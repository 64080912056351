import React, { Component } from 'react';
import {  push } from 'react-router-redux'
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Carousel from 'nuka-carousel';
import { appInsights } from '../../AppInsights';
import { setVal } from '../../actions/filters';
import {
  unifiedSearch,
  fetchHubAssemblyFilters,
  fetchHubAssemblyFiltersWithNoResults,
  saveBrakeRotorNumber,
  fetchNotificationResult,
  competitorSearch
} from '../../actions';
import Autocomplete from 'react-autocomplete';
import _ from 'lodash';

class Start extends Component {
  constructor(props) {
    super(props);
    this.doSearch = this.doSearch.bind(this);

    this.state = {
      value: '',
      partNumber: [],
      loading: false,
      url: '',
      openModel: false,
      openDetailModal: false,
      notificationId: null,
      data: null,
      currentIndex: 0,
      closeModal: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    const { dispatch, app } = this.props;
    dispatch(fetchNotificationResult());
    Modal.setAppElement('body');
    this.updateIgnore();
    if(!app.goingBack) {
      this.setState({ openModel: true });
    }
  }

  updateIgnore(){
    let notifications = JSON.parse(localStorage.getItem('notifications'));
    if (notifications && notifications.length) {
      let notification = null;
      let index = 0;
      notifications.every(function (item,i){
        if((item.ignore === undefined || item.ignore < 3) && !item.IsSilent){
          notification = item;
          index = i;
          return false;
        }
        return true;
      });
      if(notification) {
        let ignore = notification.ignore ? (notification.ignore + 1) : 1;
        if (notification.Sticky === 'true') {
          ignore = 0;
          sessionStorage.setItem('ignoreNotifications', JSON.stringify(false));
        }
        notifications[index].ignoreTemp = ignore;
        localStorage.setItem('notifications', JSON.stringify(notifications));
      }
    }
  }

  getFilteredNotification(){
    let notifications = JSON.parse(localStorage.getItem('notifications'));
    if (notifications && notifications.length) {
      let filtered = notifications.filter(this.checkOSAndVersion);
      filtered = _.sortBy(filtered, 'id');
      if (filtered && filtered.length) {
        localStorage.setItem('notifications', JSON.stringify(filtered));
      }
    }
  }

  checkOSAndVersion = (d) => {
    let os = this.getOS();
    let version = localStorage.getItem('version');
    let osPassed = true;
    if(d.TargetOS){
      let _androidDevice = os === 'Android';
      let _iOSDevice = os === 'iOS';
      let _isWeb = !_androidDevice && !_iOSDevice;
      if(_androidDevice && !/Android/i.test(d.TargetOS)){
        osPassed = false;
      }else if(_iOSDevice && !/iOS/i.test(d.TargetOS)){
        osPassed = false;
      }else if(_isWeb &&  !/Web/i.test(d.TargetOS)){
        osPassed = false;
      }
    }
    let versionPassed = true;
    if(d.Version){
      if(d.Version.indexOf('<') !== -1) {
        versionPassed = version === null || version < d.Version.replace('<','').trim();
      } else {
        versionPassed = version === d.Version;
      }
    }
    return versionPassed && osPassed
    // return d
  }

  getOS() {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      os = 'iOS';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
    return os;
  }

  closeModal() {
    this.setState({ openModel: false, closeModal: true });
    let notifications = JSON.parse(localStorage.getItem('notifications'));
    notifications.map((item, i) => (
      notifications[i].ignore = item.ignoreTemp
    ));
    localStorage.setItem('notifications', JSON.stringify(notifications));
    sessionStorage.setItem('ignoreNotifications', JSON.stringify(true));
  }

  openDetailModal(id) {
    const { dispatch } = this.props;
    dispatch(push('/hub/notification-detail/' + id));
    let notifications = JSON.parse(localStorage.getItem('notifications'));
    this.setState({ openModel: false });
    const index = notifications.findIndex((a) => a.Id === id);
    if (index > -1) {
      notifications[index] = { ...notifications[index], seen: (!notifications[index].Sticky) };
    }
    localStorage.setItem('notifications', JSON.stringify(notifications));
    let unseen = notifications.filter((a) => a.seen === false || !a.seen);
    localStorage.setItem(
      'unseenNotificationsCount',
      JSON.stringify(unseen ? unseen.length : notifications.length)
    );
  }

  onNextAction(nextSlide, slidesToShow, currentSlide, slideCount, data) {
    if (slidesToShow + currentSlide >= slideCount) {
      this.closeModal();
    }
    let notifications = JSON.parse(localStorage.getItem('notifications'));
    [currentSlide, currentSlide+1].forEach(function (v,i){
      if(!i || i+v === data.length) {
        let notification = data.find((a) => a.Id === data[v].Id);
        let index = notifications.findIndex((item) => item.Id === notification.Id);
        if (index > -1) {
          notifications[index] = {
            ...notifications[index],
            ignoreTemp: notifications[index].Sticky ? 0 : (
                (notifications[index].ignoreTemp ? notifications[index].ignoreTemp : 0) + 1),
          };
        }
      }
    });
    localStorage.setItem('notifications', JSON.stringify(notifications));
    nextSlide();
  }

  notificationDialogs() {
    const { results } = this.props;
    const { openModel, currentIndex } = this.state;
    if (openModel) {
      let notes = JSON.parse(localStorage.getItem('notifications'));
      let seenIgnore = notes.filter((a) => a.seen === true || a.ignore > 2 || a.IsSilent === true);
      let unseen = notes.filter((a) => a.seen === false || !a.seen);
      let notesWithoutSeenIgnore = notes.filter(
        (item) => !seenIgnore.includes(item)
      );
      localStorage.setItem(
        'unseenNotificationsCount',
        unseen ? unseen.length : notes.length
      );
      let data =
        notesWithoutSeenIgnore && notesWithoutSeenIgnore.length
          ? notesWithoutSeenIgnore
          : results.notifications;

      let ignoreNotes = JSON.parse(sessionStorage.getItem('ignoreNotifications'));
      if (
        seenIgnore.length < notes.length &&
        !ignoreNotes
      ) {
        return (
          <Modal
            isOpen={openModel}
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={true}
            className="notification-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div>
              <div className="modal-content">
                <div className="notification-slider">
                  <Carousel
                    slideIndex={currentIndex}
                    renderBottomCenterControls={null}
                    renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                      data.length > 1 ? (
                        <button
                          onClick={previousSlide}
                          className={`slick-arrow slick-prev ${
                            currentSlide > 0 ? '' : 'disabled'
                          }`}
                        />
                      ) : null
                    }
                    renderCenterRightControls={({
                      nextSlide,
                      slidesToShow,
                      currentSlide,
                      slideCount,
                    }) =>
                      data.length > 1 ? (
                        <button
                          onClick={() =>
                            this.onNextAction(
                              nextSlide,
                              slidesToShow,
                              currentSlide,
                              slideCount,
                              data
                            )
                          }
                          className={`slick-arrow slick-next ${
                            slidesToShow + currentSlide < slideCount
                              ? ''
                              : 'disabled'
                          }`}
                        />
                      ) : null
                    }
                    afterSlide={(index) => this.setState({ currentIndex: index })}
                  >
                    {data.map((d, i) => (
                      <div key={i} className="note">
                        <h2 className="text-center">{d.Title}</h2>
                        {d.IsUpdateButton ?
                            (
                              <div className="btn-no-description conmet-button">
                                <button onClick={() => window.location.href = d.UpdateLink ?? ''}>
                                  <h4>UPDATE NOW</h4>
                                </button>
                              </div>
                            ) : null
                        }
                        <div className="btn-no-description conmet-button">
                          <button onClick={() => this.openDetailModal(d.Id)}>
                            <h4>LEARN MORE</h4>
                          </button>
                        </div>
                        <div className="btn-no-description conmet-button">
                          <button onClick={this.closeModal}>
                            <h4>LATER</h4>
                          </button>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </Modal>
        );
      }
    }
  }

  handleClick(path) {
    const { dispatch } = this.props;
    dispatch(push(path));
  }

  doSearch(e) {
    e.preventDefault();
    this.unifiedFilters(this.state.partNumber[0]);
  }

  unifiedFilters(data) {
    if(data){
      appInsights.trackEvent({
        name: 'Unified Search',
        properties: { Competitor: data.CompetitorName, PartNumber: data.CompetitorPartNumber },
      });

      if (data.PartTypeId === 115) {
        this.getResult(data.CompetitorInterchangesId);
      } else if (data.PartTypeId === 1115) {
        this.props.dispatch(push('/hub/meritor-exclusive'));
      } else if (data.PartTypeId === 1112) {
        this.props.dispatch(push('/hub/volvo-exclusive'));
      } else if (data.PartTypeId === 1) {
        setTimeout(() => {
          this.props.dispatch(
              push('/hub/casting/filter/hamnt/hcnum=' + data.CompetitorPartNumber)
          );
        },1000);
      } else if (data.PartTypeId === 5) {
        this.props.dispatch(
            push('/hub/stud/summaries/' + data.CompetitorPartNumber)
        );
      } else if (data.PartTypeId === 23) {
        this.props.dispatch(
            push(
                '/hub/replacement-drum/recommended-drums/' +
                data.CompetitorInterchangesId
            )
        );
      } else if (data.PartTypeId === 203) {
        this.props.dispatch(saveBrakeRotorNumber(data.CompetitorPartNumber));
        setTimeout(() => {
          this.props.dispatch(
              push(
                  '/hub/replacement-rotor/recommended-rotors/' +
                  data.CompetitorInterchangesId
              )
          );
        }, 100);
        // BrakeRotorCasting
      } else if (data.PartTypeId === 202) {
        setTimeout(() => {
          this.props.dispatch(
              push('/hub/rotor-casting/filter/trtyp/ctnum=' + data.CompetitorPartNumber)
          );
        }, 100);
        // DrumCasting
      } else if (data.PartTypeId === 652) {
        setTimeout(() => {
          this.props.dispatch(
              push('/hub/drum-casting/filter/holes/ctnum=' + data.CompetitorPartNumber)
          );
        }, 100);

      }else if (data.PartTypeId === 219) {
        this.props.dispatch(
            push(
                '/hub/hub-rebuild-kits-detail/' +
                data.CompetitorPartNumber
            )
        );
      }else if (data.PartTypeId === 241) {
        this.props.dispatch(
            push(
                '/hub/abs-service-kit-detail/' +
                data.CompetitorPartNumber
            )
        );
      }else if (data.PartTypeId === 220) {
        if(data.CompetitorId === 1 && data.CompetitorName === 'ConMet'){
          this.props.dispatch(
              push(
                  '/hub/seals-detail/' +
                  data.CompetitorPartNumber
              )
          );
        }else{
          const { dispatch } = this.props;
          dispatch(competitorSearch(data.CompetitorPartNumber)).then(() => {
            const { results } = this.props;
            const sealDetail = results.partNumber;
            const partNum = sealDetail && sealDetail[0] && sealDetail[0].ConMetPartNumber;
            this.props.dispatch(
                push(
                    '/hub/seals-detail/' + partNum
                )
            );
          });
        }
      }else if (data.PartTypeId === 7) {
        if (data.CompetitorPartNumber === "10083900") {
          this.props.dispatch(
              push(
                  '/hub/abs-service-kit-detail/10086542'
              )
          );
        }else {
          this.props.dispatch(
              push(
                  '/hub/abs-tone-ring-detail/' +
                  data.CompetitorPartNumber
              )
          );
        }
      }else if (data.PartTypeId === 221) {
        this.props.dispatch(
            push(
                '/hub/seal-spacer-kit-detail/' +
                data.CompetitorPartNumber
            )
        );
      }else if (data.PartTypeId === 101) {
        if(data.CompetitorId === 1 && data.CompetitorName === 'ConMet'){
          this.props.dispatch(
              push(
                  '/hub/bearing-detail/' +
                  data.CompetitorPartNumber
              )
          );
        }else{
          const { dispatch } = this.props;
          dispatch(competitorSearch(data.CompetitorPartNumber)).then(() => {
            const { results } = this.props;
            const sealDetail = results.partNumber;
            const partNum = sealDetail && sealDetail[0] && sealDetail[0].ConMetPartNumber;
            this.props.dispatch(
                push(
                    '/hub/bearing-detail/' + partNum
                )
            );
          });
        }
      }else if (data.PartTypeId === 227) {
        this.props.dispatch(
            push(
                '/hub/spindle-nut-kit-detail/' +
                data.CompetitorPartNumber
            )
        );
      }else {
        if (this.state.partNumber.length === 1) {
          this.getResult(this.state.partNumber[0].CompetitorInterchangesId);
        } else if (
            this.state.value !== '' &&
            this.state.partNumber.length === 0 &&
            !this.state.loading
        ) {
          this.props.dispatch(push('/hub/no-results'));
        }
      }
    }else{
      this.props.dispatch(push('/hub/no-results'));
    }
  }

  getResult(id) {
    const { dispatch } = this.props;
    // const spindle = [10082224, 10082225, 10082226, 10082227, 10082228, 10082229, 10082230, 10082231, 10082249, 10082250, 10082251, 10082252, 10082253, 10082254, 10082255, 10082256];
    let url = '';
    dispatch(fetchHubAssemblyFilters(id)).then(() => {
      let filters = this.props.results.filters.Results;
      let CompetitorPartNumber = this.state.partNumber[0].CompetitorPartNumber;
      if (filters === undefined) {
        dispatch(fetchHubAssemblyFiltersWithNoResults(id)).then(() => {
          this.props.dispatch(
            push('/hub/not-available/' + CompetitorPartNumber)
          );
        });
      } else {
        let i = 1,
          length = Object.keys(filters).length;

        Object.keys(filters).forEach((key) => {
          url +=
            i === length
              ? key + '=' + filters[key]
              : key + '=' + filters[key] + '&';
          i++;
        });
        // if(spindle.includes(parseInt(CompetitorPartNumber))){
        if(filters.spints === true){
            let FILTERIDX = 24;
            dispatch(setVal(FILTERIDX,{ hcmty: filters.hcmty, brkty: filters.brkty, wmslc: filters.wmslc, whlps: filters.whlps, aaxna: filters.aaxna, tcomp: filters.tcomp, hubps: filters.hubps, strdo: filters.strdo, hamnt: filters.hamnt }));
            this.props.dispatch(push('/hub/spindle-nut'));
        }else{          
          this.props.dispatch(push('/hub/filters/' + url));
        }
      }
    });
  }

  render() {
    this.getFilteredNotification();
    let notes = JSON.parse(localStorage.getItem('notifications'));
    return (
      <div className="grid-container main-content">
        {notes ? this.notificationDialogs() : null}
        <h2>What are you looking for?</h2>
        <form id="autoComplete">
          <Autocomplete
            value={this.state.value}
            inputProps={{
              placeholder:
                'Please enter a valid ConMet or Competitor part number',
              className: 'assembly-number',
              id: 'assemblyNumber',
              type: 'text',
            }}
            wrapperStyle={{
              position: 'relative',
              display: 'block',
              margin: '0 0 1rem 0',
            }}
            items={this.state.partNumber}
            getItemValue={(item) =>
              item.CompetitorName + ' ' + item.CompetitorPartNumber
            }
            onSelect={(value, state) => {
              this.props.results.selectedHubAssemblyNumber =
                state.CompetitorName + ' ' + state.CompetitorPartNumber;
              this.unifiedFilters(state);
              this.setState({ value, partNumber: [state] });
            }}
            onChange={(event, value) => {
              this.setState({ value, partNumber: [], loading: true });
              if (value !== '') {
                this.props.dispatch(unifiedSearch(value)).then(() => {
                  let results = this.props.results.partNumber.Results;
                  if (results) {
                    this.setState({
                      partNumber: this.props.results.partNumber.Results,
                      loading: false,
                    });
                  } else {
                    this.setState({ partNumber: [], loading: false });
                  }
                });
              }
            }}
            renderItem={(item, isHighlighted) => (
              <div
                className="menu-item"
                style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                key={item.CompetitorInterchangesId || item.CompetitorPartNumber}
              >
                {item.CompetitorName} {item.CompetitorPartNumber}
              </div>
            )}
            renderMenu={(items, value) => (
              <div className="menu">
                {value === '' ? (
                  <div className="item">
                    Type ConMet or Competitor assembly number
                  </div>
                ) : this.state.loading ? (
                  <div className="item">Loading...</div>
                ) : items.length === 0 ? (
                  <div className="item">No matches for {value}</div>
                ) : (
                  items
                )}
              </div>
            )}
          />
          <div className="help">
            Some examples: “104444”, “ConMet 10031065”, “Gunite 5669‑1”, “Webb
            20231‑‑1T‑71”
          </div>
          <div className="btn-no-description conmet-button">
            <button type="submit" onClick={this.doSearch}>
              <h2 style={{ textAlign: 'center' }}>Continue</h2>
            </button>
          </div>
        </form>
        <hr />
        <div className="btn-no-description conmet-button">
          <button
            onClick={this.handleClick.bind(this, '/hub/choose-path')}
            store={this.context.store}
          >
            <h2>
              HUBS <i className="icon-angle-right" />
            </h2>
          </button>
        </div>
        <div className="btn-no-description conmet-button">
          <button
            onClick={this.handleClick.bind(this, '/parts/choose-path')}
            store={this.context.store}
          >
            <h2>
              HUB COMPONENTS{' '}
              <i className="icon-angle-right" title="Right Arrow" />
            </h2>
          </button>
        </div>
        <div className="btn-no-description conmet-button">
          <button
            onClick={this.handleClick.bind(this, '/hub/replacement-drum')}
            store={this.context.store}
          >
            <h2>
              BRAKE DRUMS <i className="icon-angle-right" />
            </h2>
          </button>
        </div>
        <div className="btn-no-description conmet-button">
          <button
            onClick={this.handleClick.bind(this, '/hub/replacement-rotor')}
            store={this.context.store}
          >
            <h2>
              BRAKE ROTORS{' '}
              <i className="icon-angle-right" title="Right Arrow" />
            </h2>
          </button>
        </div>

        {/*         <div className="btn-no-description conmet-button">
          <button onClick={this.handleClick.bind(this, '/hub/stud/filter')} store={this.context.store}>
            <h2>STUD SEARCH <i className="icon-angle-right" title="Right Arrow" /></h2>
          </button>
        </div> */}
        {/*<p className="terms-and-conditions">In using this application you are acknowledging that you have read and understand ConMet's <a href={`${window.location.origin}/ConMet-Terms-And-Conditions.pdf`} target='_blank'>terms and conditions</a></p>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(Start);
